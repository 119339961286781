import React, { Component, Fragment } from "react";
import HeaderNav from "../HeaderNav";
import ProchainementDetails from "../ProchainementDetails";
import Footer from "../Footer";
import Subscribe from "../Subscribe";



class Prochainement extends Component {
    
  render() {
    return (
      <Fragment>
        <ProchainementDetails />
        {/*<Subscribe />*/}
      </Fragment>
    );
  }
}

export default Prochainement;
