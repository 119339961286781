import React, {  useState } from "react";
import {
  CarouselProvider,
  Slider,
  Slide,
  ButtonBack,
  ButtonNext,
} from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";
import Showtimes from "./Showtimes";




export default function ShowDates(props) {

  const [selectedDate, setSelectedDate] = useState(0);

  const stringToDate = (dateString) => {
    const year = dateString.substring(0, 4);
    const month = dateString.substring(4, 6);
    const day = dateString.substring(6, 8);
    const dateSeance = new Date(year, month - 1, day);
    const dateHumain = dateSeance.get;
  }

  // give today's date as yyyymmdd
  const javascriptDate = () => {
      const today = new Date();
      const year = today.getFullYear();
      const month = ("0" + (today.getMonth() + 1)).slice(-2)
      const day =  ((today.getDate())<10)? "0"+today.getDate() : today.getDate(); // prefix a 0 to day < 10 |--> 09 instead of 9
      return year+''+month+''+day;
      //return '20200622' // debug
  }


  const renderDatesList = () => {
    const { datesShowtimes} = props;
    return datesShowtimes.map((date, index) => {
      if (selectedDate===0 && index === 0) setSelectedDate(date.date_humain)
      return (
        <Slide className={"slide-name " + ( date.date_humain===selectedDate && 'active')} 
              href="#0" 
              index={index}
              value={date.date_humain} 
              onClick={() => setSelectedDate(date.date_humain)}
              key={index}
        >
          { ( index===0 && date.date===javascriptDate() ) 
              ? 'Aujourd\'hui'
              : date.date_humain 
          }
        </Slide>
      );
    });
  }


    return (

    <div>
      <CarouselProvider
        visibleSlides={props.visibleSlides}
        totalSlides={props.totalSlides}
        step={props.step}
        naturalSlideWidth={props.naturalSlideWidth}
        naturalSlideHeight={props.naturalSlideHeight}
        totalSlides={props.totalSlides}
        orientation="horizontal"
      >
        <Slider
          className="schedule-slider"
          style={{ height: "55px", lineHeight: "3", marginBottom: "15px" }}
        >

          {renderDatesList()}


        </Slider>
        <ButtonBack className="showtimes-button-back">
          <i className="fa fa-chevron-left"></i>
        </ButtonBack>
        <ButtonNext className="showtimes-button-next">
          <i className="fa fa-chevron-right"></i>
        </ButtonNext>
      </CarouselProvider>

      <p className="text-danger vad-info-text" ><i className="fas fa-cart-plus"></i> Cliquez sur la séance pour acheter vos places</p>

        <Showtimes
          datesShowtimes={props.datesShowtimes}
          selectedDate= {selectedDate}
          key={selectedDate}
          titre={props.titre}
        />
    </div>

    );
  
  }

  
  // Connection au store redux même dans un functionnal component. Excellent !
  // mais attention dans ce cas il faut enlever export default function en haut ;)
  /*function mapStateToProps(state) {  // ou mapStateToProps({dataCines}) -> pour avoir dataCines direct
    return { dataCines: state.dataCines };
  } 
  
  export default connect(mapStateToProps)(ShowDates);*/
