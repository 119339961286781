import axios from 'axios';
import {API_BASE_URL} from '../assets/constants'


const client = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    //'Content-Type': 'application/json',
    "content-type": "application/json",
    "x-api-identifiant": "api_admin",
    "x-api-pwd": "fkfVEL*#78Kt6uvN"
  }
});

// Today's date
const today = new Date(Date.now());
const todayString = today.getFullYear()+''+(today.getMonth()+1)+''+today.getUTCDate();


export function fetchProg(cinemaId) {
  return client.get('progalldays?id_cinema='+cinemaId);
}

export function fetchProchainement(cinemaId) {
  return client.get('prochainement?id_cinema='+cinemaId);
}

export function fetchEvenements(cinemaId) {
  return client.get('evenements?id_cinema='+cinemaId);   
}

export function fetchTarifsWeb(cinemaId) {
    return client.get('prices?id_cinema='+cinemaId);   
}

export function fetchInfosAcces(cinemaId) {
  return client.get('infosacces?id_cinema='+cinemaId);   
}

export function fetchBannieresPromos(cinemaId) {
  return client.get('promos?id_cinema='+cinemaId);   
}

export function fetchProgrammePdf() {
  return client.get('programmepdf');   
}

export function postMessage(params) {
  return client.post('/contact', params);
}

