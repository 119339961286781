import React, { Component } from "react";
import FicheEvenement from "./FicheEvenement";
import { connect } from "react-redux";


class evenementsDetails extends Component {



  renderFilmsList() {
    const { evenementsData } = this.props;
    const cinemaId = evenementsData.cinemaId;
    try {
        if (Array.isArray(evenementsData.evenements[cinemaId])){
            return evenementsData.evenements[cinemaId].map( prochainement => {
              if(prochainement.result === undefined) {
                return (
                  <FicheEvenement 
                    key = { prochainement.id }
                    movieData = { prochainement}
                    prochainement = {prochainement.titre}
                  />
                )
                } else {
                  return(<h2 key="0"> Pas d'évènement en ce moment</h2>)
                }
            } )
        } else {
          return (<div className="alert alert-info" role="alert">
                      { evenementsData.error.length === 0 ?
                           'Chargement de la page...'
                      :
                          'Il y a eu un problème lors du chargement cette page, essayez de la rafraichir ou de vérifier votre connexion réseau -> '+evenementsData.error
                      }
                  </div> );
        }
    } catch (error) {
        //return  'Chargement' //<span>Désolé, une erreur est survenue. Merci de recharger la page.</span>
    }
  }






  render() {

    return (
      <div className="page">
        {<div className="page-title">
          <div className="container">
            <h1>ÉVÈNEMENTS </h1>
          </div>
        </div>
        }
        <div className="events-section inner-section">
          <div className="container">
          {/*<div className="mb10">
            <h1 className="small text-center text-md-left">Prochainement</h1>
            </div>*/}
            { this.props.evenementsData.isFetching &&
                <div className="loader" style={{margin:"0 auto", marginBottom:"15px"}}></div>
          
            }
            { this.renderFilmsList() }
          </div>
        </div>
      </div>
    );
  }
}


function mapStateToProps ({dataCines}){
  return {
      evenementsData: dataCines
  }
}


export default connect(mapStateToProps)(evenementsDetails);


//export default ProgrammeDetails;
