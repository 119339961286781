import React, { Component } from "react";
import "pure-react-carousel/dist/react-carousel.es.css";

class ShowTimes extends Component {

  stringToDate(dateString) {
    const year = dateString.substring(0, 4);
    const month = dateString.substring(4, 6);
    const day = dateString.substring(6, 8);
  }

  renderShowTimesList(dateShow) {

    const { datesShowtimes} = this.props;
    //console.log("Date sélectionnée "+dateShow+" film "+titre)
    return datesShowtimes.map((date, index) => {
      if (date.date_humain === dateShow ) {
        try {
          return date.showtimes.map( showtime => {
              return (
                  <div className="col-user col-xl-3 col-4 "  key={showtime.id_seance}> 
                    <a className="film-box" href={"https://ws.cinevauban.fr/ebillet/vente_distance.php?id_cinema="+showtime.id_cinema+"&id_seance="+showtime.id_seance+"&id_bordereau="+showtime.id_bordereau}>
                      <time className="d-block text-center ">{showtime.heure}</time>
                      <div className="d-flex justify-content-between px30">
                        <span>{showtime.version}</span>
                        <span>{showtime.relief}</span>
                      </div>
                    </a>
                  </div>
              );
            });
          } catch (exception) {
            return (<div className="col-user col-xl-3 col-4 "  key={date.date_humain}>
                      <a className="film-box" href="#">
                        <time className="d-block text-center ">Pas de séance</time>
                        <div className="d-flex justify-content-between px30"></div>
                      </a>
                    </div>
          );
          }
      }
    });
  }

  render() {
    return (
      <div className="schedule-timing row row-user align-items-stretch">
            
            {this.renderShowTimesList(this.props.selectedDate)}

      </div>
    );
  }
}

export default ShowTimes;
