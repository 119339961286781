import React, { Component } from "react";
import FicheProchainement from "./FicheProchainement";
import { connect } from "react-redux";

class ProchainementDetails extends Component {


  renderFilmsList() {
    const { prochainementData } = this.props;
    const cinemaId = prochainementData.cinemaId;
    if (Array.isArray(prochainementData.prochainement[cinemaId])){
        return prochainementData.prochainement[cinemaId].map( prochainement => { 
          if(prochainement.result === undefined) {
            return (
              <FicheProchainement 
                key = { prochainement.id }
                movieData = { prochainement}
                prochainement = {prochainement.titre}
              />
            )
          } else {
            return(<h2 key="0"> Pas de film dans cette rubrique pour l'instant</h2>)
          }

        } )
    } else {
      return (<div className="alert alert-info" role="alert">
                  { prochainementData.error.length === 0 ?
                           'Chargement de la page...'
                      :
                          'Il y a eu un problème lors du chargement cette page, essayez de la rafraichir ou de vérifier votre connexion réseau -> '+prochainementData.error
                      }
              </div> );
    }
  }






  render() {

    return (
      <div className="page">
        {<div className="page-title">
          <div className="container">
            <h1>PROCHAINEMENT </h1>
          </div>
        </div>
        }
        <div className="events-section inner-section">
          <div className="container">
          {/*<div className="mb10">
            <h1 className="small text-center text-md-left">Prochainement</h1>
            </div>*/}
            { this.props.prochainementData.isFetching &&
                <div className="loader" style={{margin:"0 auto", marginBottom:"15px"}}></div>
          
            }
            { this.renderFilmsList() }
          </div>
        </div>
      </div>
    );
  }
}


function mapStateToProps ({dataCines}){
  return {
      prochainementData: dataCines
  }
}


export default connect(mapStateToProps)(ProchainementDetails);


//export default ProgrammeDetails;
