import React, { useState } from "react";
import arrowVideo from "../assets/img/arrow.png";
import DOMPurify from "dompurify";

export default function FicheProchainement(props) {
  const [showModalVideo, setShowModalVideo] = useState(0);

  const handleVideoClick = (evt) => {
    setShowModalVideo(1);
  };
  const handleCloseVideo = (evt) => {
    setShowModalVideo(0);
  };

  return (
    <>
      <div className="mb35 card-section">
        <div className="events-preview">
          <div className="grey-box">
            <div className="danger-label mb15">{props.movieData.datedesortie}</div>
            <div className="card-image mb5">
              {props.movieData.affichette.length !== null && props.movieData.affichette.length !== undefined ? (
                <img src={"https://cloud.cinevauban.fr/files/images/" + props.movieData.affichette} alt="" />
              ) : (
                <i
                  class="fas fa-photo-video"
                  style={{
                    fontSize: "100px",
                    position: "absolute",
                    display: "block",
                    zIndex: "3",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    color: "lightgrey",
                  }}
                ></i>
              )}
            </div>
            <div className="d-flex justify-content-between mb25">
              <span className="program-info">{props.movieData.duree}</span>
              <span className="program-info">
                {props.movieData.anneeproduction} {props.movieData.nationalite}
              </span>
            </div>
            {props.movieData.video_id !== null && props.movieData.video_id !== undefined && (
              <div className="order-btn">
                <button className="btn btn-block btn-primary" onClick={handleVideoClick}>
                  <img className="btn-icon mr20" src={arrowVideo} alt="" />
                  Voir la vidéo
                </button>
              </div>
            )}

            {showModalVideo === 1 && (
              <div id="myModal" class="modal">
                <div class="modal-content" width="900" height="800">
                  <span class="close" onClick={handleCloseVideo}>
                    &times;
                  </span>
                  <iframe
                    width="100%"
                    height="100%"
                    src={"https://www.youtube.com/embed/" + props.movieData.video_id + "?autoplay=1"}
                    frameBorder="0"
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  ></iframe>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="event-description flex-fill">
          <div className="grey-box">
            <div className="mb50">
              <h3 style={{ textTransform: "capitalize" }}>{props.movieData.titre.toLowerCase()}</h3>
            </div>
            <div className="film-about">
              <div className="mb55">
                <h6 className="text-danger">Avec:</h6>
                <p>{props.movieData.acteurs}</p>
              </div>
              <div className="mb55">
                <h6 className="text-danger">Réalisé par:</h6>
                <p>{props.movieData.realisateurs}</p>
              </div>
              <div className="mb55">
                <h6 className="text-danger">Synopsis:</h6>
                <p className="lh1-7" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(props.movieData.synopsis) }}></p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mb35">
        <hr />
      </div>
    </>
  );
}

// Connection au store redux même dans un functionnal component. Excellent !
// mais ATTENTION dans ce cas il faut enlever export default function en haut ;)

/*function mapStateToProps(state) {  // ou mapStateToProps({dataCines}) -> pour avoir dataCines direct
    return { dataCines: state.dataCines };
  } 
  
  export default connect(mapStateToProps)(ShowDates);*/
