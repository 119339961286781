import React, { Component } from "react";


//import { NavLink } from "react-router-dom";
//import { connect } from "react-redux";

class Subscribe extends Component {
  render() {
    return (
      <div className="subscribe">
          <div className="container">
            <form className="subscribe-form">
                <div className="mb50">
                    <h2 className="title">Abonnez-vous à notre lettre d’information</h2>
                  </div>
              <div className="mb30">
                <ul>
                  <li>
                    <input type="radio" name="selector" id="option1" />
                    <label htmlFor="option1">Recevoir les programmes chaque semaine + les infos</label>
                    <div className="check"></div>
                  </li>
                  <li>
                    <input type="radio" name="selector" id="option2" />
                    <label htmlFor="option2">Ne recevoir que les infos</label>
                    <div className="check"></div>
                  </li>
                </ul>
              </div>
              <div className="mail-wrap mb5">
                <input className="form-control" type="mail" placeholder="Entrez votre adresse Email" /><a className="btn btn-danger" href="#0">ENVOYER</a>
              </div>
            </form>
          </div>
		</div>
    );
  }
}

export default Subscribe;
