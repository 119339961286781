import React, { Component, Fragment } from "react";
import { BrowserRouter, Route, withRouter, Switch, useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import HeaderNav from './HeaderNav'
import { connect } from "react-redux";
import Programme from "./Pages/Programme";
import Footer from './Footer'
import Prochainement from "./Pages/Prochainement";
import Evenements from "./Pages/Evenements";
import NotFound from "./Pages/NotFound";
import Infos from "./Pages/Infos";
import Contact from "./Pages/Contact";
import Entreprises from "./Pages/Entreprises";
import Accueil from "./Pages/Accueil";
import cacheAndCookie from '../assets/cache-and-cookie'

class App extends Component {


  // on utilise aussi componentDidMount dans programmesDetails car c'est la première page qu'il va recharger après le clic sur le choix du ciné
  componentDidMount() {
      // page load data under different scenarios
      const fetching = cacheAndCookie(this.props);
      //console.log(fetching)
  }



  render() {
    return (
      <div >
        <BrowserRouter>
          <Fragment>  
            <HeaderNav />{/* Header displays only if page is not / accueil; the condition statement is in headerNav file*/}
            <Switch>
              <Route exact path="/" component={Accueil} />  
              <Route  path="/Programme" component={ this.props.dataCines.selectedCinema===undefined ? Accueil : Programme }  />
              <Route path="/Prochainement" component={this.props.dataCines.selectedCinema===undefined ? Accueil : Prochainement } />
              <Route path="/Evenements" component={this.props.dataCines.selectedCinema===undefined ? Accueil : Evenements } />
              <Route path="/Infos" component={this.props.dataCines.selectedCinema===undefined ? Accueil : Infos } />
              <Route path="/Entreprises" component={this.props.dataCines.selectedCinema===undefined ? Accueil : Entreprises } />
              <Route path="/Contact" component={this.props.dataCines.selectedCinema===undefined ? Accueil : Contact } />
              <Route component={this.props.dataCines.selectedCinema===undefined ? Accueil : NotFound }/>
            </Switch>
            <Footer/>
          </Fragment>
        </BrowserRouter>
      </div>
    );
  }
}

// Component Styles
const styles = {
  loadingtyles: {
    width: "50px",
    height: "40px",
    textAlign: "center",
    fontSize: "10px",
    position: "absolute",
    left: "calc(50vw - 25px)",
    top: "calc(50vh - 20px)",
  }
};

App.propTypes = {
  //authedUser: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
};

function mapStateToProps({ dataCines }) {
  return {
    dataCines,
  };
}


export default connect(mapStateToProps)(App);
