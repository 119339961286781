import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import Leaflet from 'leaflet';
import { Map, TileLayer, Marker, Popup, Tooltip } from "react-leaflet"
import 'leaflet/dist/leaflet.css';

Leaflet.Icon.Default.imagePath =
'../node_modules/leaflet'

delete Leaflet.Icon.Default.prototype._getIconUrl;

Leaflet.Icon.Default.mergeOptions({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png')
});



// Permet d'ouvir le popup dès l'initialisation de la carte
// get access to native leaflet marker object (leafletElement) and open popup via Marker.openPopup method
// see https://reactjs.org/docs/refs-and-the-dom.html for refs and dom
const MyMarker = props => {
  const initMarker = ref => {
    if (ref) {
      console.log(ref)
      ref.leafletElement.openPopup()
    }
  }
  return <Marker ref={initMarker} {...props}/>
}





class Infos extends Component {


  // renders tarifs on top of page
  renderPrices() {
    const { prices, cinemaId } = this.props;
    try {
      // ne rien appeler avant cette ligne car si la page est rechargée les données ne sont pas encore là
      if (Array.isArray(prices[cinemaId])) {
        return prices[cinemaId].map((prices) => {
          return (
            <div className="col-12 col-md-6 col-lg-3" key={prices.id} style={{marginBottom:'15px'}}>
              <div className="price-item">
                <span className="tarif">{prices.nom_tarif}</span>
                <span className="price">{prices.montant_tarif}</span>
              </div>
            </div>
          );
        });
      } else {
        return (
          <div className="alert alert-info" role="alert">
            Il n'y a pas d'infos sur les tarifs disponibles.
          </div>
        );
      }
    } catch (error) {
      //return  'Chargement ... les données du store ne sont pas encore toutes là'
    }
  }



  // renders description tarifs on top of page
  renderDescriptionPrices() {
    const { prices, cinemaId } = this.props;
    try {
      if ( Array.isArray(prices[cinemaId]) ) {
        return prices[cinemaId].map((prices) => {
          if (prices.description.length > 0) {
              return (
                <div className="col-md-6 col-user-large mb20" key={prices.id}>
                  <div className="bordered-box bordered-rectangle h-lg-100">
                    <div className="mb10">
                      <h6 className="text-danger ttu">{prices.nom_tarif}</h6>
                    </div>
                    <p>
                      {prices.description}
                    </p>
                  </div>
                </div>
              );
           }
        });
      } else {
        return (
          <div className="alert alert-info" role="alert">
            Il n'y a pas d'infos sur les tarifs disponibles.
          </div>
        );
      }
    } catch (error) {
      //return  'Chargement ... les données du store ne sont pas encore toutes là'
    }
  }



    
  // renders description tarifs on top of page
  renderPaiementInfos() {
    const { infosAcces, cinemaId } = this.props;
    try {
      if ( Array.isArray(infosAcces[cinemaId]) ) {
        return infosAcces[cinemaId].map((infosAcces) => {
              return (
                <div key={infosAcces.id}>
                  <div className="mb10"   >
                     <h2 className="small text-center text-md-left">
                      {infosAcces.titre_paiement}
                      </h2>
                  </div>
                  <div className="grey-bordered">
                  <div className="mb20" >
                    <p dangerouslySetInnerHTML={{ __html: infosAcces.content_paiement }} style={{lineHeight: "1.8",}}></p>
                  </div>
                  </div>
                </div>
              );
        });
      } else {
        return (
          <div className="alert alert-info" role="alert">
            Il n'y a pas d'infos sur les tarifs disponibles.
          </div>
        );
      }
    } catch (error) {
      //return  'Chargement ... les données du store ne sont pas encore toutes là'
    }
  }


    // renders content paiement on top of page
    renderAccesInfos() {
      const { infosAcces, cinemaId } = this.props;
      try {
        if ( Array.isArray(infosAcces[cinemaId]) ) {
          return infosAcces[cinemaId].map((infosAcces) => {
                return (
                  <div key={infosAcces.id}>
                    <div className="mb10"  key={infosAcces.id} >
                       <h2 className="small text-center text-md-left">
                        {infosAcces.titre_acces}
                        </h2>
                    </div>
                    <div className="grey-bordered">
                    <div className="mb20" >
                      <p dangerouslySetInnerHTML={{ __html: infosAcces.content_acces }} style={{lineHeight: "1.8",}}></p>
                    </div>
                    </div>
                  </div>
                );
          });
        } else {
          return (
            <div className="alert alert-info" role="alert">
              Il n'y a pas d'infos sur les tarifs disponibles.
            </div>
          );
        }
      } catch (error) {
        //return  'Chargement ... les données du store ne sont pas encore toutes là'
      }
    }







  render() {

    const position = (this.props.cinemaId==='4169')? ['48.657519', '-1.970782'] : ['48.646957','-2.007230'];
    const zoom = '17'

    return (
      <Fragment>
        <div className="page-title">
          <div className="container">
            <h1>INFOS PRATIQUES</h1>
          </div>
        </div>
        <div className="white-box inner-section">
            { this.props.fetching &&
                <div className="loader" style={{margin:"0 auto", marginBottom:"15px"}}></div>
            }
          <div className="container">
            <div className="mb15">
              <h2 className="small text-center text-md-left">Tarifs</h2>
            </div>
            <div className="grey-box mb35">
              <div className="row"> 
                {this.renderPrices()}  
              </div>
            </div>

            <section className="post mb35">
              <div className="row d-flex align-items-ld-stretch">
                    {this.renderDescriptionPrices()} 
              </div>
            </section>

            <section className="post mb80">
                  {this.renderPaiementInfos()} 
            </section>
           
            <section className="post mb55">
                {this.renderAccesInfos()} 
            </section>

            <Map center={position} zoom={zoom} style={{height : '600px'}}>
                  <TileLayer
                    attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                  />
                  <MyMarker position={position}>
                    
                      {(this.props.cinemaId==='4169')
                      ? <Popup><h4>CinéVauban St-Malo</h4> <p style={{fontSize:"16px"}}>45 rue de la Croix Désilles<br/> 35400 Saint-MALO<br/> (à coté de Leclerc et de l’IUT).</p> </Popup>
                      : <Popup><h4>Le Vauban La Grande Passerelle</h4> <p style={{fontSize:"16px"}}>Esplanade de la gare<br/> 35400 Saint-MALO<br/></p></Popup>
                      }
                    
                  </MyMarker>
            </Map>


          </div>
        </div>

      </Fragment>
    );
  }
}

function mapStateToProps({ dataCines }) {
  return {
    cinemaId: dataCines.cinemaId,
    prices: dataCines.prices,
    infosAcces: dataCines.infosacces,
    fetching: dataCines.isFetching,
  };
}

export default connect(mapStateToProps)(Infos);
