import React, { Component } from "react";
import FicheFilm from "./FicheFilm";
import { connect } from "react-redux";
import cacheAndCookie from '../assets/cache-and-cookie'

class ProgrammeDetails extends Component {


  componentDidMount() {
    // page load data under different scenarios
    const fetching = cacheAndCookie(this.props);
  }

  renderFilmsList() {
    const { dataCines } = this.props;
    const cinemaId = dataCines.cinemaId;

    if (Array.isArray(dataCines.programme[cinemaId])) {
      return dataCines.programme[cinemaId].map((prog) => {
        if(prog.result === undefined) {
          return (
            <FicheFilm
              key={prog.visa}
              movieData={prog.movie_data}
              datesShowtimes={prog.dates}
              numberOfDates={prog.dates.length}
              firstShowDate={prog.dates[0]}
              titreCotecine={prog.titre_cotecine}
            />
          )
          } else {
            return(<h2> Pas d'évènement en ce moment</h2>)
          }
      });
    } else {
      return (
        <div className="alert alert-info" role="alert">
          {
              dataCines.error.length === 0 ?
               'Chargement de la page...'
            :
                'Il y a eu un problème lors du chargement cette page, essayez de la rafraichir ou de vérifier votre connexion réseau -> '+dataCines.error
          }
        </div>
      );
    }

  }

  render() {
    return (
      <div className="main-program">
        <div className="container">
          <div className="mb10">
            <h1 className="small text-center text-md-left">Programme</h1>
          </div>
          {this.props.dataCines.isFetching && (
            <div
              className="loader"
              style={{ margin: "0 auto", marginBottom: "15px" }}
            ></div>
          )}
          {this.renderFilmsList()}
        </div>
      </div>
    );
  }
}

function mapStateToProps({ dataCines }) {
  return {
    dataCines,
  };
}

export default connect(mapStateToProps)(ProgrammeDetails);

//export default ProgrammeDetails;
