import React, { useState } from "react";
import arrowVideo from "../assets/img/arrow.png";
import Showdates from "./Showdates";
//import { NavLink } from "react-router-dom";
//import { connect } from "react-redux";

export default function FicheFilm(props) {

  
  const [showModalVideo, setShowModalVideo] = useState(0);

  const handleVideoClick = (evt) => {
    setShowModalVideo(1);
  }
  const handleCloseVideo = (evt) => {
    setShowModalVideo(0);
  }

    return (
      <div className="card mb20">
        <div className="order-box">
          <div className="card-image mb5">
            { props.movieData.result === undefined ?
              <img  src={  "https://cloud.cinevauban.fr/files/images/" +props.movieData[0].affichette  } alt=""  />
              :
               <i className="fas fa-photo-video" style={{fontSize: "100px", position:"absolute", display:"block", zIndex:"3", top:"50%", left: "50%",transform: "translate(-50%, -50%)",color: "lightgrey"}}></i>
            }
          </div>
          { props.movieData.result === undefined &&
            <div className="d-flex justify-content-between mb25">
            <span className="program-info">
              {props.movieData[0].duree}
            </span>
            <span className="program-info">
              {props.movieData[0].anneeproduction}{" "}
              {props.movieData[0].nationalite}
            </span>
          </div>
          }
          {  ( props.movieData[0] !== undefined && props.movieData[0].video_id !== null && props.movieData[0].video_id.length > 0) &&
							<div className="order-btn">
								<button className="btn btn-block btn-primary" onClick={handleVideoClick} >
									<img className="btn-icon mr20" src={arrowVideo} alt=""/>Voir la vidéo</button>
							</div>
          }
              { // Modal for video
                showModalVideo === 1 &&
                <div id="myModal" className="modal">
                  <div className="modal-content"  >
                    <span className="close" onClick={handleCloseVideo} >&times;</span>
                    <iframe width="100%" height="100%" src={"https://www.youtube.com/embed/"+props.movieData[0].video_id+"?autoplay=1"} frameBorder="0" allow="fullscreen;accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                  </div>
                </div>
              }
        </div>

 
        <div className="info-box">

        { props.movieData.result === undefined ?
          <div className="film-about mb40">
            <div className="mb20">
              <h4 style={{textTransform:"capitalize",}}>{props.movieData[0].titre.toLowerCase()}</h4>
            </div>
            <div className="mb20">
              <p>{props.movieData[0].genreprincipal}</p>
            </div>
            <div className="mb20">
              <h6 className="text-danger">Avec:</h6>
              <p>{props.movieData[0].acteurs}</p>
            </div>
            <div className="mb25">
              <h6 className="text-danger">Réalisé par:</h6>
              <p>{props.movieData[0].realisateurs}</p>
            </div>
            <div className="mb20">
              <h6 className="text-danger">Synopsis:</h6>
              <p className="lh1-7" dangerouslySetInnerHTML={{ __html: props.movieData[0].synopsis }} ></p>
            </div>
          </div>
          :
          <div className="mb20">
              <h4 style={{textTransform:"capitalize",}}>{props.titreCotecine.toLowerCase()}</h4>
          </div>
        }
          <div></div>

          <div className="d-none d-lg-block">

            <Showdates
              visibleSlides={4}
              step={3}
              naturalSlideWidth={400}
              naturalSlideHeight={500}
              totalSlides={props.numberOfDates}
              datesShowtimes={props.datesShowtimes}
              firstShowDate={props.firstShowDate}
            />

          </div>
        </div>
      
        <div className="clearfix"></div>
		
        <div className="d-block d-lg-none mobile-slider">
          {/*It is same section as we use before - just for mobile I have to change it's position*/}
          <div className="slider-wrap mb15">
            <Showdates
              visibleSlides={2}
              step={2}
              naturalSlideWidth={400}
              naturalSlideHeight={500}
              totalSlides={props.numberOfDates}
			        datesShowtimes={props.datesShowtimes}
			        firstShowDate={props.firstShowDate}
            />

          </div>

        </div>
      </div>
    );
  }

 // Connection au store redux même dans un functionnal component. Excellent !
  // mais attention dans ce cas il faut enlever export default function en haut ;)
  /*function mapStateToProps(state) {  // ou mapStateToProps({dataCines}) -> pour avoir dataCines direct
    return { dataCines: state.dataCines };
  } 
  
  export default connect(mapStateToProps)(ShowDates);*/
