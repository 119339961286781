import getCookie from "./get-cookie";
import { fetchDataCine, cinemaSelection } from "../actions";
import { TTL_TIME_LAPSE } from "./constants";

/* Function to manage the fetching of data under several scenarios
    1 -> No Cookie
    2 -> Cookie Enable 
            |_ a -> TTL defined (user navigating pages app fetching only after TTL_TIME_LAPSE is over)
            |_ b -> TTL undefined yet (refresh of page)
*/

export default function cacheAndCookie(props) {


    // Si le cookie n'est pas défini on redirige vers la page d'accueil pour obliger à choisir un cinéma -> voir les routes ci-dessous
    const cookieSelectedCinema = getCookie("selectedCinema");
    const cookieCinemaId = getCookie("cinemaId");

    // Case : no cookie
    if (cookieSelectedCinema.length === 0) {
            const ttl = props.dataCines.dataTTL[props.dataCines.cinemaId];
            if (ttl === undefined || Date.now() > ttl + TTL_TIME_LAPSE)
                props.dispatch(fetchDataCine(props.dataCines.cinemaId));
                return true;
    // Case : cookie is enable
    } else {
            // if the props dataTTL is not set yet then we use 0
            const ttl = props.dataCines.dataTTL[props.dataCines.cinemaId];

            // Case : api call to get cine data
            if (Date.now() > ttl + TTL_TIME_LAPSE && ttl > 0) {
                props.dispatch(fetchDataCine(cookieCinemaId,props.dataCines.dataTTL[cookieCinemaId]) );
                return true;
            }

            // Case : refresh of current page
            if (ttl === undefined) {
                props.dispatch(cinemaSelection(cookieSelectedCinema,cookieCinemaId));
                props.dispatch(fetchDataCine(cookieCinemaId));
                return true;
            }
    }

    return false;
}


