import React, { Component } from "react";

//import { NavLink } from "react-router-dom";
//import { connect } from "react-redux";

class HeaderNav extends Component {
  render() {
    return (
      <footer className="footer">
        <div className="container">
          <div className="text-center">
            <a className="social d-block mb20" href="https://www.facebook.com/cinevauban/">
              <span className="d-block mb10">
                <i className="fab fa-facebook-square"></i>
              </span>
              <span>nous suivre</span>
            </a>
            <a className="copy" href="#">
              Tous droits réservés © 2020 Mentions Légales | Réalisation 1000 MONDES
            </a>
          </div>
        </div>
      </footer>
    );
  }
}

export default HeaderNav;
