


/* fetch all data for one cine */
export function fetchDataCine(cinema_id, lastTimeOfFetching = 0) {
  return {
    type: "FETCH_DATACINE_STARTED",
    payload : {
      cinemaId: cinema_id,
      lastTimeOfFetching : lastTimeOfFetching,
    }
  };
}


export function fetchProg(cinema_id, lastTimeOfFetching = 0) {
    return {
      type: "FETCH_PROG_STARTED",
      payload : {
        cinemaId: cinema_id,
        lastTimeOfFetching : lastTimeOfFetching,
      }
    };
}

export function fetchProchainement(cinema_id, lastTimeOfFetching = 0) {
  return {
    type: "FETCH_PROCHAINEMENT_STARTED",
    payload : {
      cinemaId: cinema_id,
      lastTimeOfFetching : lastTimeOfFetching,
  }
  };
}


export function cinemaSelection(cinema,cinema_id) {
    return {
      type: "CINEMA_SELECTION",
      payload : {
          selectedCinema : cinema,
          cinemaId: cinema_id
      }
    };
}

export function currentPath(path) {
  return {
    type: "CURRENT_PATH",
    payload : {
        currentPath : path,
    }
  };
}

