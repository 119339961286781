import { TTL_TIME_LAPSE } from "../assets/constants";

const initialProjectsState = {
  programme: [],
  prochainement: [],
  evenements: [],
  isFetching: false,
  error: '',
  dataTTL: {},
  currentPath:'',
  bannieresPromos: {}
};

export function dataCines(state = initialProjectsState, action) {
  switch (action.type) {
    case "FETCH_DATACINE_STARTED": {
      return {
        ...state,
        isFetching: (Date.now() > action.payload.lastTimeOfFetching + TTL_TIME_LAPSE) ? true  : false,
        isLoading: (Date.now() > action.payload.lastTimeOfFetching + TTL_TIME_LAPSE) ? true : false,
      };
    }
    // ce cas met à jour le store pour tous les objects du ciné avec la data qui vient de la saga
    case "FETCH_DATACINE_SUCCEEDED": {
      const { cinemaId, dataProg, prochainement, evenements, prices, infosAcces, programmePdf, bannieresPromos } = action.payload;
      const progCine = new Object(); const  progProchainement = new Object(); const  progEvenements = new Object(); const progPrices = new Object(); const progAcces = new Object();  const progBannieres = new Object(); 
      progCine[cinemaId] = dataProg; // Dynamically create object with cinemaId -> new array of prog
      progProchainement[cinemaId] = prochainement;
      progEvenements[cinemaId] = evenements;
      progPrices[cinemaId] = prices;
      progAcces[cinemaId] = infosAcces;
      progBannieres[cinemaId] = bannieresPromos;

      return {
        ...state,
        programme: { ...state.programme, ...progCine },
        programmePdf: { ...state.programmePdf, ...programmePdf },
        prochainement: { ...state.prochainement, ...progProchainement },
        evenements: { ...state.evenements, ...progEvenements },
        prices: { ...state.prices, ...progPrices },
        infosacces: { ...state.infosacces, ...progAcces },
        bannieresPromos: { ...state.bannieresPromos, ...progBannieres },
        dataTTL: { ...state.dataTTL, ...{ [cinemaId]: Date.now() } }, // + simple au lieu de l'objet const dataTtl = new Object(); dataTtl[cinemaId] = Date.now()
        isFetching: false,
        isLoading: false,
      };
    }
    case "FETCH_DATACINE_FAILED": {
      return {
        ...state,
        isFetching: false,
        isLoading: false,
        error: action.payload.error,
      };
    }

    case "CINEMA_SELECTION": {
      return {
        ...state,
        selectedCinema: action.payload.selectedCinema,
        cinemaId: action.payload.cinemaId,
      };
    }
    case "CURRENT_PATH": {
      return {
        ...state,
        currentPath: action.payload.currentPath,
      };
    }
    /*case "FETCH_PROG_STARTED": {
      return {
        ...state,
        isFetching: (Date.now() > action.payload.lastTimeOfFetching + TTL_TIME_LAPSE) ? true  : false,
        isLoading: (Date.now() > action.payload.lastTimeOfFetching + TTL_TIME_LAPSE) ? true : false,
      };
    }
    case "FETCH_PROG_SUCCEEDED": {
      const { cinemaId, dataProg } = action.payload;
      const progCine = new Object();
      progCine[cinemaId] = dataProg; // Dynamically create object with cinemaId -> new array of prog
      return {
        ...state,
        programme: { ...state.programme, ...progCine },
        dataTTL: { ...state.dataTTL, ...{ [cinemaId]: Date.now() } }, // + simple au lieu de l'objet const dataTtl = new Object(); dataTtl[cinemaId] = Date.now()
        isFetching: false,
        isLoading: false,
      };
    }
    case "FETCH_PROG_FAILED": {
      return {
        ...state,
        isFetching: false,
        isLoading: false,
        error: action.payload.error,
      };
    }

    case "FETCH_PROCHAINEMENT_STARTED": {
      return {
        ...state,
        isFetching: (Date.now() > action.payload.lastTimeOfFetching + TTL_TIME_LAPSE) ? true  : false,
        isLoading: (Date.now() > action.payload.lastTimeOfFetching + TTL_TIME_LAPSE) ? true  : false,
      };
    }
    case "FETCH_PROCHAINEMENT_SUCCEEDED": {
      const { cinemaId, prochainement} = action.payload;
      const progProchainement = new Object();
      progProchainement[cinemaId] = prochainement;
      return {
        ...state,
        prochainement: { ...state.prochainement, ...progProchainement },
        dataTTL: { ...state.dataTTL, ...{ [cinemaId]: Date.now() } },
        isFetching: false,
        isLoading: false,
      };
    }
    case "FETCH_PROCHAINEMENT_FAILED": {
      return {
        ...state,
        isFetching: false,
        isLoading: false,
        error: action.payload.error,
      };
    }*/
    default: {
      return state;
    }
  }
}
