import React, { Component, Fragment  } from 'react'
import HeaderNav from '../HeaderNav'
import Footer from '../Footer'
import Subscribe from '../Subscribe'


class NotFound extends Component {
    render() {
        return(
          <Fragment>
              <section className="mb10 hero is-medium is-danger is-bold" >
                  <div className="hero-body">
                    <div className="container" style={{ textAlign: 'center', marginTop:'150px', marginBottom:'150px'}}>
                      <h1 className="title">
                        Cette page n'existe pas (404)
                      </h1>
                      <h2 className="subtitle">
                        Merci d'utiliser la barre de navigation ci-dessus
                      </h2>
                    </div>
                  </div>
              </section>
            </Fragment>
        )
    }
}

export default NotFound