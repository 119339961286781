import { call, put, all, takeLatest, delay } from "redux-saga/effects";
import * as api from "../api";
import {TTL_TIME_LAPSE} from '../assets/constants'

export default function* rootSaga() {   // noter l'asterix => c'est un générateur
  //yield takeLatest("FETCH_PROG_STARTED", watchFetchProg); // takeLatest cancels old processes when a new one begins
  //yield takeLatest("FETCH_PROCHAINEMENT_STARTED", watchFetchProchainement); // takeLatest cancels old processes when a new one begins
  yield takeLatest("FETCH_DATACINE_STARTED", watchFetchDatacine);
}


/*
 * Cette méthode fetch toutes les méthodes API dont on a besoin pour les différentes pages et infos du cinéma sélectionné
 */

function* watchFetchDatacine({ payload, type }) {
        //console.log("quel type :?" + type + " et payload :" + payload.cinemaId);
        try {
            // if the TTL is due or not set then we fetch data from server No otherwise
            if ( Date.now()  >  payload.lastTimeOfFetching + TTL_TIME_LAPSE ){

                // yield all to compose several call at once
                const { progData, prochData, eveData, pricesData, infosAccesData, programmePdfData, bannieresPromosData } = yield all({   
                            progData: call(api.fetchProg, payload.cinemaId),
                            prochData: call(api.fetchProchainement, payload.cinemaId),
                            eveData: call(api.fetchEvenements, payload.cinemaId),
                            pricesData: call(api.fetchTarifsWeb, payload.cinemaId),
                            infosAccesData: call(api.fetchInfosAcces, payload.cinemaId),
                            programmePdfData: call(api.fetchProgrammePdf),
                            bannieresPromosData: call(api.fetchBannieresPromos, payload.cinemaId)
                })
                // yield put all the data to the reducer
                yield put({
                    type: "FETCH_DATACINE_SUCCEEDED",
                    payload: { 
                            dataProg: progData.data, 
                            prochainement: prochData.data,
                            evenements: eveData.data,
                            prices: pricesData.data,
                            infosAcces: infosAccesData.data,
                            programmePdf: programmePdfData.data,
                            bannieresPromos: bannieresPromosData.data,
                            cinemaId: payload.cinemaId  },
                });
            } 
        } catch (e) {
            yield put({
                type: "FETCH_DATACINE_FAILED",
                payload: { error: e.message },
            });
        }
}

// Initial SAGA -> kept for reference
function* watchFetchProg({ payload, type }) {
            //console.log("quel type :?" + type + " et payload :" + payload.cinemaId);
            //while (true) {     // No more infinite loop is required, because takeLatest continues to listen for the action type.
                try {
                    // if the TTL is due or not set then we fetch data from server No otherwise
                    if ( Date.now()  >  payload.lastTimeOfFetching + TTL_TIME_LAPSE ){
                        const { data } = yield call(api.fetchProg, payload.cinemaId); 
                        yield put({
                        //put is used to dispatch an action
                        type: "FETCH_PROG_SUCCEEDED",
                        payload: { dataProg: data, cinemaId: payload.cinemaId  },
                        });
                    } 
                } catch (e) {
                    yield put({
                    type: "FETCH_PROG_FAILED",
                    payload: { programme: e.message },
                    });
                }
            //}
}


// Initial SAGA -> kept for reference
function* watchFetchProchainement({ payload, type }) {
        //console.log("quel type :?" + type + " et payload :" + payload.cinemaId + " time fetching " + payload.lastTimeOfFetching);
        try {
            // if the TTL is due or not set then we fetch data from server No otherwise
            if ( Date.now()  >  payload.lastTimeOfFetching + TTL_TIME_LAPSE ){
                const { data } = yield call(api.fetchProchainement, payload.cinemaId);
                yield put({
                    //put is used to dispatch an action
                    type: "FETCH_PROCHAINEMENT_SUCCEEDED",
                    payload: { prochainement: data, cinemaId: payload.cinemaId },
                });
            }
        } catch (e) {
                yield put({
                type: "FETCH_PROCHAINEMENT_FAILED",
                payload: { error: e.message },
            });
        }
}
