import React, { useState } from "react";
import logoLeft from "../assets/img/logo/logo-left.png";
import logoLeftGrey from "../assets/img/logo/logo-left-grey.png";
import logoRight from "../assets/img/logo/logo-right.png";
import logoRightGrey from "../assets/img/logo/logo-right-grey.png";
import { NavLink, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { cinemaSelection, fetchDataCine } from "../actions";




function HeaderNav(props) {

  const [mobileMenu, setMobileMenu] = useState(0);

  const handleChangeCinema = (evt) => {
          const cinema = evt.target.name;
          const cinema_id = evt.target.id;

          const condition = navigator.onLine ? 'online' : 'offline';

          // TODO : create a more elaborated rule so that we check if we have data for both cinemas and then authorize to see the cinema
          // for example if offline and the user select a cinema that has already been fetched we only dispatch cinemaSelection and not fetchDataCine
          if( condition==='offline' ) {
              alert(`Votre connexion internet est indisponible.\n Vous pouvez cependant continuer à naviguer sur les pages de ce cinéma, mais vous ne pouvez pas en sélectionner un autre tant que votre connexion n'est pas rétablie.`)
              return;
          }

          // We save the selected cinema in the store
          props.dispatch(cinemaSelection(cinema, cinema_id));
          
          // We now fetch the prog ... we don't need to chain the two actions because we already know what cinemaId is selected
          props.dispatch(fetchDataCine(cinema_id,props.dataTTL[cinema_id]));

          // fermer le menu mobile
          if (mobileMenu) setMobileMenu(!mobileMenu);

          // Set cookie for selectedCinema
          var d = new Date();
          d.setTime(d.getTime() + 24 * 60 * 60 * 1000); // cookie expires in 24 hours
          var expires = ";expires=" + d.toUTCString();
          document.cookie = "selectedCinema=" + cinema + ";samesite=strict" + expires;
          document.cookie = "cinemaId=" + cinema_id + ";samesite=strict" + expires;

  };

  // Ouvrir le menu (ou le fermer)
  const handleBurgerClick = (e) => {
    setMobileMenu(!mobileMenu);
  };
  // Fermer le menu ou l'ouvrir
  const handleMenuItemClick = (e) => {
    setMobileMenu(!mobileMenu);
  };

  // Porgramme PDF
  const handleDownloadProgramme = (e) => {
    if (props.programmePdf !=='undefined'){
      window.open(props.programmePdf[0].fichier) 
    }
  };


  const { selectedCinema } = props;


  /*  -------- ATTENTION LORSQUE C'EST LA PAGE D'ACCUEIL ON N'AFFICHE PAS LE HEADER --------- */
  if (props.location.pathname === '/' || selectedCinema === undefined) {
    return null
  }



  return (
    <header className="header">
      <div className="container-fluid">
        <nav
          className="navbar navbar-expand-lg d-flex align-items-stretch flex-wrap flex-lg-nowrap justify-content-between"
          id="lp-nav"
        >
          <div className="d-flex d-lg-inline-flex mobile-100p">
            <div className="logo">
              <NavLink
                className="logo-left"
                activeClassName="active "
                to="/Programme"
                name="cinevauban"
                id="4169"
              >
                { selectedCinema === "cinevauban"  ? (
                  <img
                    src={logoLeft}
                    alt=""
                    name="cinevauban"
                    id="4169"
                    onClick={handleChangeCinema}
                  />
                ) : (
                  <img
                    src={logoLeftGrey}
                    alt=""
                    name="cinevauban"
                    id="4169"
                    onClick={handleChangeCinema}
                  />
                )}
              </NavLink>
              <NavLink
                className="logo-right"
                activeClassName="active"
                to="/Programme"
                name="lapasserelle"
                id="4171"
                onClick={handleChangeCinema}
              >
                { selectedCinema === "lapasserelle" ? (
                  <img
                    src={logoRight}
                    alt=""
                    name="lapasserelle"
                    id="4171"
                    onClick={handleChangeCinema}
                  />
                ) : (
                  <img
                    src={logoRightGrey}
                    alt=""
                    name="lapasserelle"
                    id="4171"
                    onClick={handleChangeCinema}
                  />
                )}
              </NavLink>
            </div>

            <button
              className="navbar-toggler ml-auto"
              type="button"
              data-toggle="collapse"
              data-target="#page-nav"
              aria-controls="page-nav"
              aria-expanded={mobileMenu}
              aria-label="Toggle navigation"
              onClick={handleBurgerClick}
            >
              <span className="icon-bar icon-bar1"></span>
              <span className="icon-bar icon-bar2"></span>
              <span className="icon-bar icon-bar3"></span>
            </button>
          </div>
          <div className="d-flex d-lg-inline-flex flex-row flex-xl-wrap ml-auto mobile-100p">
            <div
              className={
                !mobileMenu
                  ? "collapse navbar-collapse"
                  : "collapse navbar-collapse show"
              }
              id="page-nav"
            >
              <ul className="navbar-nav h-lg-100">
                <li
                  className={`nav-item ${
                    props.location.pathname === "/Programme" ? "active" : ""
                  }`}
                >
                  <NavLink
                    className="nav-link "
                    to="/Programme"
                    activeStyle={
                      props.location.pathname === "/Programme"
                        ? { color: "#ff0065" }
                        : { display: "" }
                    }
                    onClick={handleMenuItemClick} // refermer le menu
                  >
                    PROGRAMME
                  </NavLink>
                </li>
                <li
                  className={`nav-item ${
                    props.location.pathname === "/Prochainement" ? "active" : ""
                  }`}
                >
                  {/*<!--a className="nav-link" href="soon.html"-->*/}
                  <NavLink
                    className="nav-link"
                    to="/Prochainement"
                    activeStyle={{ color: "#ff0065" }}
                    onClick={handleMenuItemClick}
                  >
                    PROCHAINEMENT
                  </NavLink>
                </li>
                <li
                  className={`nav-item ${
                    props.location.pathname === "/Evenements" ? "active" : ""
                  }`}
                >
                  <NavLink
                    className="nav-link"
                    to="/Evenements"
                    activeStyle={{ color: "#ff0065" }}
                    onClick={handleMenuItemClick}
                  >
                    ÉVÈNEMENTS
                  </NavLink>
                </li>
                <li
                  className={`nav-item ${
                    props.location.pathname === "/Infos" ? "active" : ""
                  }`}
                >
                  <NavLink
                    className="nav-link"
                    to="/Infos"
                    activeStyle={{ color: "#ff0065" }}
                    onClick={handleMenuItemClick}
                  >
                    INFOS PRATIQUES
                  </NavLink>
                </li>
                <li
                  className={`nav-item ${
                    props.location.pathname === "/Entreprises" ? "active" : ""
                  }`}
                >
                  <NavLink
                    className="nav-link"
                    to="/Entreprises"
                    activeStyle={{ color: "#ff0065" }}
                    onClick={handleMenuItemClick}
                  >
                    ENTREPRISES CE
                  </NavLink>
                </li>
                <li
                  className={`nav-item ${
                    props.location.pathname === "/Contact" ? "active" : ""
                  }`}
                >
                  <NavLink
                    className="nav-link"
                    to="/Contact"
                    activeStyle={{ color: "#ff0065" }}
                    onClick={handleMenuItemClick}
                  >
                    CONTACT
                  </NavLink>
                </li>
              </ul>
              <div className="d-block d-lg-inline-block mr-lg-40">
                  <button
                    className="btn btn-block btn-danger"
                    data-toggle="modal"
                    data-target="#main-modal"
                    onClick={handleDownloadProgramme}
                  >
                    Télécharger le programme
                  </button>
              </div>
            </div>
          </div>
        </nav>
      </div>
    </header>
  );
}

function mapStateToProps({ dataCines }) {
  return {
    selectedCinema: dataCines.selectedCinema,
    dataTTL: dataCines.dataTTL,
    cinemaId: dataCines.cinemaId,
    programmePdf: dataCines.programmePdf,
  };
}

//export default withRouter(connect(mapStateToProps)(HeaderNav));
export default withRouter(connect(mapStateToProps)(HeaderNav));
