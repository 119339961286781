import React, { Component, Fragment } from "react";
import EvenementsDetails from "../EvenementsDetails";




class Prochainement extends Component {
    
  render() {
    return (
      <Fragment>
        <EvenementsDetails />
        {/*<Subscribe />*/}
      </Fragment>
    );
  }
}

export default Prochainement;
