import React, { Component, Fragment  } from 'react'
import { Link, withRouter } from 'react-router-dom'
import { cinemaSelection, currentPath } from "../../actions";
import { connect } from "react-redux";
import '../../assets/css/accueil.css';


class Accueil extends Component {


  handleCinemaSelection = (evt) => {
    // We 'save' the cinemaId to use it all over the app
    const cinema = evt.target.name;
    const cinema_id = evt.target.id;
    
    // Set cookie for selectedCinema
    var d = new Date();
    d.setTime(d.getTime() + ( 24 * 60 * 60 * 1000));  // cookie expires in 24 hours
    var expires = ";expires="+d.toUTCString();
    document.cookie = "selectedCinema="+cinema+";samesite=strict"+expires;
    document.cookie = "cinemaId="+cinema_id+";samesite=strict"+expires;
    // Record the cine selected in store
    this.props.dispatch(cinemaSelection(cinema,cinema_id));

  }




  render() {
    // To detect IE
    const ieVersion = (document.documentMode ? document.documentMode : 99);

      return(
        <Fragment>
          { /*ieVersion*/ }
            
                <div className="clip-wrap">

                  <figure className="clip-item clip-left">
                    <Link  to="/Programme" className="clip-link clip-css" width="100%" height="750" onClick={this.handleCinemaSelection} name="cinevauban" id="4169" >
                        <img src={require('../../assets/img/accueil/logo-left-lg.png')} alt="" className="clip-logo mobile-hide" onClick={this.handleCinemaSelection} name="cinevauban" id="4169" />
                        <img src={require('../../assets/img/accueil/logo-left.png')} alt="" className="clip-logo mobile-show" onClick={this.handleCinemaSelection} name="cinevauban" id="4169" />
                    </Link>
                  </figure>

                  <figure className="clip-item clip-right">
                    <Link  to="/Programme" className="clip-link clip-css-reverse"  width="100%"  height="750" onClick={this.handleCinemaSelection} name="lapasserelle" id="4171" >
                      <img src={require('../../assets/img/accueil/logo-right-lg.png')} alt="" className="clip-logo mobile-hide" onClick={this.handleCinemaSelection} name="lapasserelle" id="4171" />
                      <img src={require('../../assets/img/accueil/logo-right.png')} alt="" className="clip-logo mobile-show" onClick={this.handleCinemaSelection} name="lapasserelle" id="4171" />
                    </Link>
                  </figure>

              	</div>
            
          </Fragment>
      )
  }
}


export default connect()(Accueil);