import React from 'react';
import { connect } from "react-redux";
// import Swiper core and required components
import SwiperCore, { Navigation, Pagination, Scrollbar } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';
import 'swiper/components/scrollbar/scrollbar.scss';
// Import cinevauban promos style
import '../assets/css/slider.css';


// install Swiper components
SwiperCore.use([Navigation, Pagination, Scrollbar]);


// Button click for avant-premiere
const handleBannerLink = (e) => {
    window.open(e.target.value) 
};



// Render the swipers one by one in response to API data
// Swipper docs : https://swiperjs.com/demos/
const renderPromosSwipers = (props) => {
    const { bannieresPromos, cinemaId } = props;
    try {
        if (Array.isArray(bannieresPromos[cinemaId])) {
            return bannieresPromos[cinemaId].map((banniere) => {
                return (
                        <SwiperSlide key={banniere.id}>
                            { (banniere.banniere_image_seule.length > 0) 
                            ?   
                                <div className="slide-item" style={{paddingTop:"0px",paddingBottom:"20px"}}>
                                    { banniere.lien_promo.length > 0
                                        ?
                                        <a href={banniere.lien_promo} >
                                            <img className="banner-image-seule" src={ "https://cloud.cinevauban.fr/files/images/" +banniere.banniere_image_seule } alt="" />
                                        </a>
                                        :
                                        <img className="banner-image-seule" src={ "https://cloud.cinevauban.fr/files/images/" +banniere.banniere_image_seule } alt="" />
                                    }      
                                </div>
                            :
                                <div className="slide-item">
                                    <div className="film-description">
                                        <div className="mb20">  
                                        <h4 className="mb10">{banniere.type_promo}</h4>
                                        <h2 className="large">
                                                {banniere.titre}
                                        </h2>
                                        <p className="mb5" style={{whiteSpace:"pre-line",}} dangerouslySetInnerHTML={{ __html: banniere.description}}></p>
                                        </div>
                                        <h3>{banniere.date_promo}</h3>
                                        { (banniere.lien_promo.length > 0) &&
                                            <button
                                                className="btn btn-block btn-danger"
                                                data-toggle="modal"
                                                data-target="#main-modal"
                                                value={banniere.lien_promo}
                                                onClick={handleBannerLink}
                                            >
                                                Réserver
                                            </button>
                                        }
                                    </div>
                                    <div className="film-image">
                                    { (banniere.affiche.length > 0) 
                                    ?
                                        <img src={ "https://cloud.cinevauban.fr/files/images/" +banniere.affiche } alt="" />
                                    :
                                        <i className="fas fa-photo-video" style={{fontSize: "100px", position:"absolute", display:"block", zIndex:"3", top:"50%", left: "50%",transform: "translate(-50%, -50%)",color: "lightgrey"}}></i>
                                    }
                                    </div>
                                </div>
                            }
                        </SwiperSlide>
                );
            });
        }
    } catch (error) {
        //return  'Chargement ... les données du store ne sont pas encore toutes là'
    }
}



// The MAIN PROMOSWWIPER function // Swipper docs : https://swiperjs.com/demos/
const PromosSwiper = (props) => {
    const { bannieresPromos, cinemaId } = props;
    try {
        if (Array.isArray(bannieresPromos[cinemaId]) &&  bannieresPromos[cinemaId][0].result === undefined ) {
            return (
                <div className="slider-section">
                    <Swiper
                    spaceBetween={50}
                    slidesPerView={1}
                    navigation
                    loop={true}
                    pagination={{ clickable: true }}
                    //scrollbar={{ draggable: true }}
                    autoHeight={true} 
                    //onSwiper={(swiper) => console.log(swiper)}
                    //onSlideChange={() => console.log('slide change')}
                    >

                        { renderPromosSwipers(props) }

                    </Swiper>
                </div>
            );
        } else {
            return <div></div>; // case where there is no banner for a cinema
        }
    } catch (error) {
        //return  'Chargement ... les données du store ne sont pas encore toutes là'
    }   
};




// Connection au store redux même dans un functionnal component. Excellent !
function mapStateToProps({ dataCines }) { // ou mapStateToProps(state) -> pour importer tout le state
    return { 
        cinemaId: dataCines.cinemaId,
        bannieresPromos: dataCines.bannieresPromos };
} 
  
export default connect(mapStateToProps)(PromosSwiper);