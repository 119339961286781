import registerServiceWorker, { unregister } from "./registerServiceWorker";
import React from "react";
import ReactDOM from "react-dom";
import "./assets/css/main.css";
import App from "./components/App";
import { dataCines } from "./reducers";
import { createStore, applyMiddleware } from "redux";
import { Provider } from "react-redux";
import { composeWithDevTools } from "redux-devtools-extension";

import createSagaMiddleware from "redux-saga";
import rootSaga from "./sagas/sagas";

const sagaMiddleware = createSagaMiddleware();

const rootReducer = (state = {}, action) => {
  return {
    dataCines: dataCines(state.dataCines, action),
    //tasks: tasks(state.tasks, action),
    //page: page(state.page, action),
  };
};

// ne faire apparaitre le redux store/dev tools qu'en développement
// sinon appliquer le sagaMiddleware
const devTools =
  process.env.NODE_ENV === "production" ? applyMiddleware(sagaMiddleware) : composeWithDevTools(applyMiddleware(sagaMiddleware));

const store = createStore(
  rootReducer,
  //composeWithDevTools( applyMiddleware(sagaMiddleware) )
  devTools
);
sagaMiddleware.run(rootSaga);

unregister();

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById("root")
);

if (module.hot) {
  module.hot.accept("./components/App", () => {
    const NextApp = require("./components/App").default;
    ReactDOM.render(
      <Provider store={store}>
        <NextApp />
      </Provider>,
      document.getElementById("root")
    );
  });

  module.hot.accept("./reducers", () => {
    const nextRootReducer = require("./reducers").default;
    store.replaceReducer(nextRootReducer);
  });
}

//registerServiceWorker();
